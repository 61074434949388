export const WebviewCallbackKeys = {
  forgotPin: 'forgotPin',
  showAccountsList: 'showAccountsList',
  closeWebview: 'closeWebview',
  closeWebviewWithoutConfirmation: 'closeWebviewWithoutConfirmation',
  clipoard: 'clipboard',
  webviewLoaded: 'webviewLoaded',
  downloadHistoryTransactions: 'downloadHistoryTransactions',
  shareReceipt: 'shareReceipt',
  downloadReceipt: 'downloadReceipt',
  verifyPin: 'verifyPin',
  downloadStatement: 'downloadStatement',
  sapaRaya: 'sapaRaya',
  openSavingAccount: 'openSavingAccount',
  changeAccount: 'changeAccount',
  finishRegistration: 'finishRegistration',
  downloadMassReceipt: 'downloadMassReceipt',
  invitationSakuAnak: 'invitationSakuAnak',
  photoYouthRejected: 'photoYouthRejected',
  checkQuotaLiveness: 'checkQuotaLiveness',
  closeSakuYouth: 'closeSakuYouth',
  showTransferSakuYouth: 'showTransferSakuYouth',
  openQrisSakuYouth: 'openQrisSakuYouth',
  configurationSecureData: 'configurationSecureData',
  openTrustDevice: 'openTrustDevice',
  openSmartLogin: 'openSmartLogin',
  getValueSmartLogin: 'getValueSmartLogin',
  openQrisView: 'openQrisView',
  shareDownloadInvoice: 'shareDownloadInvoice',
  downloadStatementSakuAnak: 'downloadStatementSakuAnak',
  openFaqView: 'openFaqView',
  openFaqLottery: 'openFaqLottery',
  fetchCustomerData: 'fetchCustomerData',
  takePhoto: 'takePhoto',
  getPosAndPlace: 'getPosAndPlace',
  handleChooseImageFromGallery: 'handleChooseImageFromGallery',
  shareQrisImage: 'shareQrisImage',
  downloadQrisImage: 'downloadQrisImage',
  openBrowser: 'openBrowser',
  handleLocationPermission: 'handleLocationPermission',
  selectContact: 'selectContact',
  shareInvitation: 'shareInvitation',
};
