import { QRIS } from './../../types/QRIS';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Authorization } from 'types/Authorization';
import { UserSakuBisnis, UserUangSaku } from 'types/User';

export interface UserState {
  sakuBisnis?: UserSakuBisnis;
  uangSaku?: UserUangSaku;
  auth?: Authorization;
}

const initialState: UserState = {
  sakuBisnis: undefined,
  uangSaku: undefined,
  auth: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserSakuBisnis: (state, action: PayloadAction<UserSakuBisnis>) => {
      state.sakuBisnis = {
        ...state.sakuBisnis,
        ...action.payload,
      };
    },
    updateUserUangSaku: (state, action: PayloadAction<UserUangSaku>) => {
      state.uangSaku = {
        ...state.uangSaku,
        ...action.payload,
      };
    },
    setAuthorization: (state, action: PayloadAction<Authorization>) => {
      state.auth = action.payload;
    },
    clearUser: (state) => {
      state.auth = undefined;
      state.sakuBisnis = undefined;
      state.uangSaku = undefined;
    },
    updateMainQRIS: (state, action: PayloadAction<QRIS>) => {
      (state.sakuBisnis ?? ({} as UserSakuBisnis)).mainQRIS = {
        ...state.sakuBisnis?.mainQRIS,
        ...action.payload,
      };
    },
    setMainQRIS: (state, action: PayloadAction<QRIS | undefined>) => {
      (state.sakuBisnis ?? ({} as UserSakuBisnis)).mainQRIS = action.payload;
    },
  },
});

export const {
  updateUserSakuBisnis,
  updateUserUangSaku,
  updateMainQRIS,
  setAuthorization,
  clearUser,
  setMainQRIS,
} = userSlice.actions;

export default userSlice.reducer;
